import { useEffect, useState } from "react";
import Subscribe from "../../Components/Subscribe";
import "./style.css";
import ReactHlsPlayer from "react-hls-player";
import { getChannels } from "../../Api";
import { Zoom } from "react-reveal";
export default function Channel(frame) {
  const url = new URL(window.location);
  var param = url.searchParams.get("key");
  const [channel, setChannel] = useState();
  useEffect(() => {
    getChannels().then((data) => {
      data.map((em) => {
        if (em.ID == param) {
          setChannel(em);
        }
      });
    });
  }, []);
  return (
    <div className="channel">
      {/*  <ReactHlsPlayer
        src={param}
        autoPlay={false}
        controls={true}
        width="100%"
        height="auto"
      /> */}
      <Zoom>
        <div
          dangerouslySetInnerHTML={{ __html: channel && channel.Frame }}
        ></div>
      </Zoom>

      <Subscribe></Subscribe>
    </div>
  );
}
