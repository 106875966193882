import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import "./style.css";
import {
  addChannel,
  changeChannel,
  deleteChannel,
  domen,
  getChannels,
  uploadImage,
} from "../../Api";
import { Store } from "react-notifications-component";
import { Link } from "react-router-dom";
export default function Admin() {
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [file, setFile] = useState(null);
  const [fileChange, setFileChange] = useState(null);
  const [channels, setChannels] = useState([]);
  const [name, setName] = useState("");
  const [frame, setFrame] = useState("");
  const [currentItem, setCurrentItem] = useState(null);
  const handleCurrent = (e, name) => {
    setCurrentItem({ ...currentItem, [name]: e.target.value });
  };
  const handleChange = (file) => {
    setFile(file);
  };
  const handleChangeChange = (file) => {
    uploadImage(file).then((data) => {
      setCurrentItem({ ...currentItem, Img: domen + data });
    });
  };
  useEffect(() => {
    getChannels().then((data) => {
      setChannels(data);
    });
  }, []);
  console.log(currentItem);
  const [change, isChange] = useState(false);
  useEffect(() => {
    currentItem && isChange(true);
  }, [currentItem]);
  function notice(title, text) {
    Store.addNotification({
      title: title,
      message: text,
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__bounceIn"],
      animationOut: ["animate__animated", "animate__backOutUp"],
      dismiss: {
        duration: 2000,
        onScreen: true,
      },
    });
  }

  function sendChannel() {
    uploadImage(file).then((path) => {
      addChannel(name, path, frame).then((status) => {
        if (status == 200) {
          getChannels().then((data) => {
            notice("Добавление канала", "Канал успешно добавлен!");
            setChannels(data);
          });
        }
      });
    });
  }
  return (
    <div className="admin">
      <div className="adminBody">
        <div className="leftAdmin">
          <h3>Список каналов: </h3>
          {channels &&
            channels.length > 0 &&
            channels.map((em, index) => {
              return (
                <div
                  className="channelItem"
                  onClick={() => {
                    setCurrentItem(em);
                  }}
                  style={
                    currentItem && currentItem.ID == em.ID
                      ? {
                          background: "#00c28b",
                        }
                      : {}
                  }
                >
                  <img src={domen + em.Img} alt="" />
                  <div className="infoChannel">
                    <p>
                      {em.Name}{" "}
                      <Link
                        to={"/channel?key=" + em.ID}
                        target="_blank"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="goto"
                      >
                        Перейти к каналу
                      </Link>
                    </p>
                    <div dangerouslySetInnerHTML={{ __html: em.Frame }}></div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="rightAdmin">
          <h3>
            <span
              style={!change ? { color: "#fff" } : {}}
              onClick={() => {
                isChange(false);
                setCurrentItem(null);
              }}
            >
              Добавить /
            </span>

            <span style={change ? { color: "#fff" } : {}}> Изменить</span>
          </h3>
          {!change ? (
            <div className="form">
              <input
                type="text"
                placeholder="Название канала"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <textarea
                value={frame}
                onChange={(e) => setFrame(e.target.value)}
                name=""
                id=""
                placeholder="Название фрейма"
                rows={8}
              ></textarea>
              <FileUploader
                className="fileUp"
                handleChange={handleChange}
                name="file"
                types={fileTypes}
              />
              <div className="buttons">
                <div className="save" onClick={() => sendChannel()}>
                  Добавить
                </div>
                <div
                  className="back"
                  onClick={() => {
                    setName("");
                    setFrame("");
                    setFile(null);
                  }}
                >
                  Очистить
                </div>
              </div>
            </div>
          ) : (
            <div className="form">
              <input
                type="text"
                placeholder="Название канала"
                name="Name"
                value={currentItem && currentItem.Name}
                onChange={(e) => handleCurrent(e, e.target.name)}
              />

              <textarea
                value={currentItem && currentItem.Frame}
                onChange={(e) => handleCurrent(e, e.target.name)}
                name="Frame"
                id=""
                placeholder="Название фрейма"
                rows={8}
              ></textarea>
              <FileUploader
                className="fileUp"
                handleChange={handleChangeChange}
                name="file"
                types={fileTypes}
              />
              <div className="buttons">
                <div
                  className="save"
                  onClick={() =>
                    changeChannel(currentItem).then((status) => {
                      if (status == 200) {
                        getChannels().then((data) => {
                          setChannels(data);
                          notice("Изменение канала", "Канал успешно изменен!");
                        });
                      }
                    })
                  }
                >
                  Добавить
                </div>
                <div
                  className="back"
                  onClick={() => {
                    deleteChannel(currentItem.ID).then((status) => {
                      if (status == 200) {
                        getChannels().then((data) => {
                          setChannels(data);
                          notice("Удаление канала", "Канал успешно удален!");
                        });
                      }
                    });
                  }}
                >
                  Удалить
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
