import "./style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-creative";
import img1 from "./img/1.png";
import img2 from "./img/2.png";
import img3 from "./img/3.png";
import img4 from "./img/4.png";
import img5 from "./img/5.png";
import img6 from "./img/6.png";
import img7 from "./img/7.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Subscribe from "../../Components/Subscribe";
import { getChannels } from "../../Api";
import { Fade, Slide, Zoom } from "react-reveal";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation } from "swiper/modules";
export default function Main() {
  const [active, setActive] = useState(0);
  const Navigate = useNavigate();
  const [channels, setChannels] = useState([]);
  useEffect(() => {
    getChannels().then((data) => {
      setChannels(data);
    });
  }, []);
  return (
    <div className="Main">
      <Fade top>
        <Swiper
          grabCursor={true}
          effect={"creative"}
          creativeEffect={{
            prev: {
              shadow: false,
              translate: ["-20%", 0, -1],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          modules={[EffectCreative, Navigation]}
          className="channels"
          onActiveIndexChange={(index) => setActive(index.activeIndex)}
        >
          {channels.length > 0 &&
            channels.map((em, index) => {
              return (
                <SwiperSlide
                  key={"channel" + index}
                  onClick={() => {
                    Navigate("/channel?key=" + em.ID);
                  }}
                >
                  <div className="watch">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.25 1.875H3.75C3.0625 1.875 2.5 2.4375 2.5 3.125V11.875C2.5 12.5625 3.0625 13.125 3.75 13.125H16.25C16.9375 13.125 17.5 12.5625 17.5 11.875V3.125C17.5 2.4375 16.9375 1.875 16.25 1.875ZM12.1938 8.0375L9.06875 9.9125C8.96875 9.975 8.85625 10 8.75 10C8.64375 10 8.5375 9.975 8.44375 9.91875C8.25 9.80625 8.125 9.6 8.125 9.375V5.625C8.125 5.4 8.24375 5.19375 8.44375 5.08125C8.6375 4.96875 8.88125 4.975 9.075 5.0875L12.2 6.9625C12.3875 7.075 12.5063 7.28125 12.5063 7.5C12.5063 7.71875 12.3938 7.925 12.2 8.0375H12.1938Z"
                        fill="white"
                      />
                      <path
                        d="M16.875 15.625H15.5125C15.2562 14.9 14.5688 14.375 13.75 14.375C12.9312 14.375 12.25 14.9 11.9875 15.625H3.125C2.78125 15.625 2.5 15.9062 2.5 16.25C2.5 16.5938 2.78125 16.875 3.125 16.875H11.9875C12.2438 17.6 12.9312 18.125 13.75 18.125C14.5688 18.125 15.25 17.6 15.5125 16.875H16.875C17.2188 16.875 17.5 16.5938 17.5 16.25C17.5 15.9062 17.2188 15.625 16.875 15.625Z"
                        fill="white"
                      />
                    </svg>
                    Запустить трансляцию
                  </div>
                  <img src={em.Img} alt="" />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </Fade>
      <div className="dots">
        <svg
          width="30"
          style={{ transform: "rotate(180deg)" }}
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="30"
            height="30"
            rx="15"
            fill="url(#paint0_linear_577_734)"
          />
          <path
            d="M13 9L19 15L13 21"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_577_734"
              x1="35.9756"
              y1="10.875"
              x2="-12.4665"
              y2="11.2718"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8052FF" />
              <stop offset="1" stop-color="#428EFF" />
            </linearGradient>
          </defs>
        </svg>

        {channels.length > 0 &&
          channels.map((em, index) => {
            return (
              <Slide left>
                <div
                  style={
                    index == active
                      ? {
                          background: "#fff",
                          width: "5.5vw",
                          height: "5.5vw",
                        }
                      : {}
                  }
                  className="dot"
                ></div>
              </Slide>
            );
          })}
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="30"
            height="30"
            rx="15"
            fill="url(#paint0_linear_577_734)"
          />
          <path
            d="M13 9L19 15L13 21"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_577_734"
              x1="35.9756"
              y1="10.875"
              x2="-12.4665"
              y2="11.2718"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8052FF" />
              <stop offset="1" stop-color="#428EFF" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      {/*    <Zoom>
        <div className="time">У вас осталось 8 дней!</div>
      </Zoom>
      <svg
        className="grs"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="24"
          height="24"
          rx="12"
          fill="url(#paint0_linear_95_1083)"
        />
        <path
          d="M11.6685 16.0815C11.8516 16.2645 12.1484 16.2645 12.3315 16.0815L15.3146 13.0983C15.4976 12.9153 15.4976 12.6185 15.3146 12.4354C15.1315 12.2524 14.8347 12.2524 14.6517 12.4354L12 15.0871L9.34835 12.4354C9.16529 12.2524 8.8685 12.2524 8.68544 12.4354C8.50238 12.6185 8.50238 12.9153 8.68544 13.0983L11.6685 16.0815ZM11.5312 7.5L11.5312 15.75L12.4687 15.75L12.4688 7.5L11.5312 7.5Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_95_1083"
            x1="28.7805"
            y1="8.69999"
            x2="-9.97319"
            y2="9.01745"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#8052FF" />
            <stop offset="1" stop-color="#428EFF" />
          </linearGradient>
        </defs>
      </svg> */}
      <Subscribe></Subscribe>
    </div>
  );
}
